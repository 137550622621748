import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import CargaArchivosDinamicos from '../components/pages/CargaArchivosDinamicos';
import Axijubilado from '../data/components/axijubilado/axijubilado.json';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const CargaArchivosPensionadosPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <CargaArchivosDinamicos pageContext={pageContext} companyName={pageContext.company} title="Documentos para pensionados" data={Axijubilado} icon={FileCopyIcon} />
      </>
    )}
  />
  </GlobalFooterProvider>
)

export default CargaArchivosPensionadosPage